@import 'reset.css';

.css-mhc70k-MuiGrid-root>.MuiGrid-item {
  padding-top: 0 !important;
}


.css-1d6wzja-MuiButton-startIcon {
  margin-right: 0 !important;
}

.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.css-17vbkzs-MuiFormControl-root-MuiTextField-root {
  /*margin-top: 0 !important;*/
}

.css-1bhpkcd-MuiTableCell-root {
padding: 0 !important;
}
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  /*padding: 10px !important;*/
}