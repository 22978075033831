@import 'reset.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap');

* {
  box-sizing: border-box;
}

html {
  font-family: 'Noto Sans JP', sans-serif;
}

body {
  margin: 0;
}

#page-wrap {
  padding-bottom: 10px;
  padding-top: 10px;
}


.tableResult{
  padding-top: 0 !important;
}