body {
    margin: 0;
    padding: 0;
}

.login-container {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 400px;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.login-container h2 {
    margin-top: 0;
    text-align: center;
    font-size: 2rem;
}

.login-container form {
    display: flex;
    flex-direction: column;
}

.login-container label {
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.login-container input[type='email'],
.login-container input[type='password'] {
    padding: 10px;
    border-radius: 5px;
    border: none;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
}

.login-container button[type='submit'] {
    margin-top: 20px;
    padding: 10px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
}

.login-container button[type='submit']:hover {
    background-color: #3e8e41;
}